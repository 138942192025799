import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { Container, SectionPage, SectionTitle } from "../Section"

const ListService =  styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -5px;
  @media(min-width:576px){
    margin:0 -10px;
  }
  @media(min-width:1200px){
    margin:0 -15px;
    padding:20px 0 0;
  }
`
const ListItemService =  styled.div`
  position:relative;
  width:100%;
  padding:0 5px;
  flex:0 0 50%;
  max-width:50%;
  @media(min-width:576px){
    padding:0 10px;
  }
  @media(min-width:992px){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  @media(min-width:1200px){
    padding:0 15px;
  }
`
const CardService =  styled.div`
  display: block !important;
  position:relative;
  background-color:#F4FBFF;
  border-radius:15px;
  text-align:center; 
  padding:30px 10px; 
  margin-bottom:10px;
  height: calc(100% - 10px);
  @media(min-width:576px){   
    margin-bottom:20px;
    height: calc(100% - 20px);
  }
  @media(min-width:1200px){
    padding:40px 10px;    
    margin-bottom:30px;
    height: calc(100% - 30px);
  }
`
const CardIcon =  styled.div`
  display:flex;
  align-items: center;
  justify-content:center;
  margin-bottom:10px;
  > svg{
      fill:#FF3E00;
  }

  
`
const CardText =  styled.div`
  > p{
  color: #062c44;
  font-weight:500;
  margin-bottom:0;
  font-size: 14px;
  line-height: 24px;
  @media(min-width:768px){
    font-size: 16px;
    line-height: 26px;
  }
  @media(min-width:992px){
    font-size: 18px;
    line-height: 30px;
  }
  }
`

const Service = () => {
    return(
        <SectionPage
          ept="80px"
          epb="50px"
          xpt="40px"
          xpb="20px"
          pt="20px"
          pb="10px"
          bg="#fff">            
          <Container maxWidth="1140px">
            <SectionTitle>Why Choose Probuilt Steel</SectionTitle>
            <ListService>
                <ListItemService>
                    <CardService className='hvr-icon-wobble-vertical'>
                        <CardIcon className='hvr-icon'><StaticImage src="../../images/free-shipping.png" alt="Free Delivery & Installation" /></CardIcon>
                        <CardText><p>Free Delivery & Installation</p></CardText>
                    </CardService>
                </ListItemService>
                <ListItemService>
                    <CardService className='hvr-icon-wobble-vertical'>
                        <CardIcon className='hvr-icon'><StaticImage src="../../images/made-usa.png" alt="We Only Use US Steel" /></CardIcon>
                        <CardText><p>We Only Use US Steel</p></CardText>
                    </CardService>
                </ListItemService>
                <ListItemService>
                    <CardService className='hvr-icon-wobble-vertical'>
                        <CardIcon className='hvr-icon'><StaticImage src="../../images/payment.png" alt="Multiple Financing Options" /></CardIcon>                        
                        <CardText><p>Multiple Financing Options</p></CardText>
                    </CardService>
                </ListItemService>
                
                <ListItemService>
                    <CardService className='hvr-icon-wobble-vertical'>
                        <CardIcon className='hvr-icon'><StaticImage src="../../images/customize-building.png" alt="100% Customization on All Buildings" /></CardIcon>                                                
                        <CardText><p>100% Customization on All Buildings</p></CardText>
                    </CardService>
                </ListItemService>
                <ListItemService>
                    <CardService className='hvr-icon-wobble-vertical'>
                        <CardIcon className='hvr-icon'><StaticImage src="../../images/fair-price.png" alt="Fair & Affordable Price" /></CardIcon>                                                                        
                        <CardText><p>Fair & Affordable Price</p></CardText>
                    </CardService>
                </ListItemService>
                <ListItemService>
                    <CardService className='hvr-icon-wobble-vertical'>
                        <CardIcon className='hvr-icon'><StaticImage src="../../images/trust.png" alt="20+ Years of Expertise & Trust" /></CardIcon>
                        <CardText><p>20+ Years of Expertise & Trust</p></CardText>
                    </CardService>
                </ListItemService>
            </ListService>
          </Container>
      </SectionPage>
    )
}

export default Service