import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { Container, SectionPage } from "../Section"
import QuickContactForm from "../QuickContactForm"
import {
    HeroFormWrap,
    HeroFormTitle,
    HeroFormBody,
  } from "../Hero"

const BackgroundImage = styled.div`	
	position: absolute;
	top: 0;
    left:0;
	height: 100%;
	width:100%;
    overflow:hidden;
    .gatsby-image-wrapper{
        width: 100%;
        height: 100%;
    }
    &:after{
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,0.7);
    }
    &:before{
        content: '';
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        left: -50px;
        bottom: -50px;
        border: 30px solid #FF3E00;        
        @media (min-width: 992px){
            width: 200px;
            height: 200px;
            left: -100px;
            bottom: -100px;
            border: 50px solid #FF3E00;
        }
    }
`

const FogrmGrid = styled.div`
    position:relative;
    z-index:1;
	display: flex;
    flex-wrap: wrap;
    align-items:center;
    margin:0 -15px;    
    @media (min-width: 768px){
        padding:0 30px;
    }
    @media (min-width: 992px){
        margin:0 -20px;
    }
    @media (min-width: 1200px){
        margin:0 -30px;
    }
    @media (min-width: 1600px){
        margin:0 -50px;
    }
`
const FogrmItemGrid = styled.div`
	position: relative;
    width: 100%;
    padding:0 15px;
	@media (min-width: 992px){
        padding:0 20px;
		flex: 0 0 50%;
		max-width: 50%;
	}
    @media (min-width: 1200px){
        padding:0 30px;
    }
    @media (min-width: 1600px){
        padding:0 50px;
    }
`

const FormSubtitle = styled.p`
    font-family: Chakra Petch, sans-serif;
    font-weight: 700;
    color: #fff;
    margin-bottom:0;
    font-size: 20px;
    line-height: 30px;
    text-align:center;
    @media (min-width: 768px) {  
        font-size: 24px;
        line-height: 34px; 
    }
    @media (min-width: 992px) { 
        text-align:left;
        font-size: 26px;
        line-height: 36px; 
    }
    @media (min-width: 1200px) {  
        font-size: 30px;
        line-height: 40px; 
    }
    @media (min-width: 1600px) {  
        font-size: 34px;
        line-height: 44px; 
    }
`

const FormTitle = styled.h2`
    color: #fff;
    width:100%;
    margin-bottom:15px;
    font-size: 30px;
    line-height: 40px;
    text-align:center;
    @media (min-width: 768px) {  
        font-size: 40px;
        line-height: 50px;
    }
    @media (min-width: 992px) {  
        margin-bottom:0;
        text-align:left;
        font-size: 60px;
        line-height: 70px;
    }
    @media (min-width: 1200px) {  
        font-size: 80px;
        line-height: 90px;
    }
    @media (min-width: 1600px) {  
        font-size: 100px;
        line-height: 110px;
    }
`


const EstimateFrom = (location) => {
    return(
        <SectionPage ept="60px" epb="60px" xpt="40px" xpb="40px" pt="30px" pb="30px" bg="#000" className='section-estimate-form'>
            <BackgroundImage><StaticImage src="../../images/experts.jpg" alt="Estimate From" /></BackgroundImage>
            <Container>
                <FogrmGrid>
                    <FogrmItemGrid>
                        <FormSubtitle>Get Free Price Estimate From</FormSubtitle>
                        <FormTitle>Our Experts Today!</FormTitle>
                    </FogrmItemGrid>
                    <FogrmItemGrid>
                        <HeroFormWrap>
                            <HeroFormTitle>Get In Touch</HeroFormTitle>
                            <HeroFormBody>
                                <QuickContactForm location={location} formName="Popular Metal Building" />
                            </HeroFormBody>
                        </HeroFormWrap>           
                    </FogrmItemGrid>
                </FogrmGrid>
            </Container>
        </SectionPage>
    )
}

export default EstimateFrom