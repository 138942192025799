import React from 'react'
import styled from 'styled-components'
import SecondaryButton from "../Button/SecondaryButton"
import PhoneIcon from "../Icons/PhoneIcon"
import { StaticImage } from "gatsby-plugin-image"
import { Container, SectionPage } from "../Section"

const SectionExpertHelp = styled.div`	
    position:relative;
    background-color:#06274D;
    border-radius:20px;
    overflow:hidden;
    padding:30px;
    @media (min-width: 992px){
        padding:40px;
    }
    @media (min-width: 1200px){
        padding:50px;
    }
    @media (min-width: 1600px){
        padding:60px;
    }
`
const BackgroundImage = styled.div`	
	position: absolute;
	top: 0;
    left:0;
	height: 100%;
	width:100%;
    border-radius:20px;
    overflow:hidden;
    .gatsby-image-wrapper{
        width: 100%;
        height: 100%;
    }
`

const Grid = styled.div`
	display: flex;
    flex-wrap: wrap;
`
const GridLeft = styled.div`
	position: relative;
    width: 100%;
    @media (min-width: 576px){
        flex: 0 0 80%;
        max-width: 80%;
    }
	@media (min-width: 768px){
		flex: 0 0 100%;
		max-width: 100%;
	}
`
const Content = styled.div`	
	& h2 {
		color: #fff;
        width:100%;
        margin-bottom:10px;
		@media (min-width: 768px) {  
            max-width:300px;
        }
        @media (min-width: 1200px) {  
            margin-bottom:15px;
            max-width:420px;
        }
	}
	& p {
		color: #fff;
		@media (min-width: 1200px) {  
			font-size: 20px;
			line-height: 30px;
		}
	}
`
const ButtonToolbar = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content:flex-start;
	
`



const ExpertHelp = () => {
    return(
        <SectionPage pt="0" pb="0" className='section-expert'>
            <Container>
                <SectionExpertHelp>
                    <BackgroundImage><StaticImage src="../../images/call-bg.jpg" alt="Struggling to Find the Right Metal Building" /></BackgroundImage>
                        <Grid>
                            <GridLeft>
                                <Content>
                                    <h2>Struggling to Find the Right Metal Building?</h2>
                                    <p>We make it easy—skip the confusion and get expert guidance for the perfect fit.</p>
                                </Content>
                                <ButtonToolbar>   
                                    <a href="tel:8777541818" aria-label="(877) 754-1818" className='btn-lg'><SecondaryButton text="Call Now for Expert Help" icon={<PhoneIcon />}/></a>
                                </ButtonToolbar>
                            </GridLeft>
                        </Grid>
                </SectionExpertHelp>
            </Container>
        </SectionPage>
    )
}

export default ExpertHelp