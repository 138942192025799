import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Container } from "../../components/Section"
import PhoneIcon from "../../components/Icons/PhoneIcon"
import SecondaryButton from "../../components/Button/SecondaryButton"
import QuotePopup from "../../components/QuotePopup"
import { StaticImage } from "gatsby-plugin-image"

const HeroCoverWrap = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  background-color:#F4FBFF;
`
const HeroCaption = styled.div`
  text-align: center;
  @media (min-width: 576px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 10;
    top: 90px;
  }
  @media (min-width: 768px) {
    top: 90px;
  }
  @media (min-width: 1200px) {
    top: 130px;
  }
  @media (min-width: 1400px) {
    top: 140px;
  }
  @media (min-width: 1600px) {
    top: 140px;
  }
  @media (min-width: 1900px) {
    top: 180px;
  }
  & h1 {
    letter-spacing: 1px;
    color: #062c44;    
    margin:0 auto 5px auto;
    width:100%;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 576px) {
      font-size: 26px;
      line-height: 36px;
    }
    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 40px;
    }
    @media (min-width: 992px) {    
      font-size: 40px;
      line-height: 50px;
    }
    @media (min-width: 1200px) {          
      font-size: 40px;
      line-height: 50px;
      max-width:600px;
      margin-bottom:10px;
    }
    @media (min-width: 1400px) {    
      font-size: 50px;
      line-height: 60px;
      margin-bottom:20px;
      max-width:650px;
    }
    
    @media (min-width: 1600px) {    
      font-size: 76px;
      line-height: 86px;
      max-width:none;
    }

  }
  & p {
    color: #062c44;
    margin-bottom: 10px;
    font-weight:500;
    font-size: 16px;
    line-height: 26px;
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 28px;
    }
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 30px;
    }
    @media (min-width: 1200px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
`

const HeroInnerCaption = styled.div`

`
const HeroButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 -5px;
  @media (min-width: 576px) {
    margin: 0 -10px;
  }
`
const HeroItemButton = styled.div`
  position: relative;
  padding: 5px;
  @media (max-width: 575.98px) {
    .btn {
      padding-left: 12px;
      padding-right: 12px;
      .icon {
        display: none;
      }
      .text {
        margin-left: 0;
      }
    }
  }
  @media (min-width: 576px) {
    padding: 0 10px;
  }
`

const HeroCoverBanner = styled.div`
  position: relative;
 
`

const HeroCover = ({ location }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const HandleModalOpen = () => {
    setIsModalVisible(true)
  }
  const HandleModalClose = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    if (isModalVisible) {
      document.querySelector("body").classList.add("modal-open")
    } else {
      document.querySelector("body").classList.remove("modal-open")
    }
  }, [isModalVisible, setIsModalVisible])

  return (
    <>
      <HeroCoverWrap>
        <HeroCoverBanner>
          <StaticImage src="../../images/hero-banner.jpg" alt="Metal Buildings" layout="fullWidth" />
        </HeroCoverBanner>
        <HeroCaption>
          <Container maxWidth="962px">
            <HeroInnerCaption>
              <h1>Durable, Custom Metal Buildings That Last</h1>
              <p>From design to delivery, we provide high-quality, long-lasting steel structures</p>
              <HeroButton>
                <HeroItemButton className="hero-btn">
                  <a href="tel:8777541818" aria-label="(877) 754-1818">
                    <SecondaryButton text="Call Us Now" icon={<PhoneIcon />} />
                  </a>
                </HeroItemButton>
                <HeroItemButton className="hero-btn">
                  <button
                    type="button"
                    aria-label="Get A Free Quote"
                    onClick={HandleModalOpen}
                  >
                    <SecondaryButton text="Get A Free Quote" />
                  </button>
                </HeroItemButton>
              </HeroButton>
            </HeroInnerCaption>
          </Container>
        </HeroCaption>
      </HeroCoverWrap>
      <QuotePopup
        isVisible={isModalVisible}
        onClose={HandleModalClose}
        location={location}
        formName="HomePage Quote Form"
      />
    </>
  )
}

export default HeroCover
