import React, { Component } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from "styled-components";
import ProductCardList from "../ProductCardList"

const Tabs = styled.div`
    padding:0;
`

const TabNav = styled.div`
    display: flex;
    flex-wrap:wrap;
    margin:0 -5px 15px -5px;
    padding:10px 0 30px;
    @media(min-width:1200px){
        margin:0 -10px 20px -10px;
        padding:15px 0 20px;
    }
    @media(min-width:1600px){
        padding:20px 0 60px;
    }
    @media (max-width: 991.98px) {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        white-space: nowrap;
        scroll-behavior: smooth;
    }
`
const TabItem = styled.div`
    display:flex;
    flex-wrap:nowrap;
    align-items:center;    
    padding:0 5px;
    cursor: pointer;
    @media(min-width:992px){
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    @media(min-width:1200px){
        padding:0 10px;
    }
`
const TabContent = styled.div``

const CategoryCard = styled.div`
  position:relative; 
`;

const CategoryFigure = styled.div`  
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
  @media(min-width:1200px){
    border-radius: 15px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CategoryName = styled.div`
  position: relative;
  background: #fff;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  line-height: 26px;
  padding: 4px 12px;
  margin: -10px 10px 0 10px;
  font-weight:500;
  width:120px;
  color: ${props => props.color};
  @media(min-width:992px){
      font-weight:600;
      padding: 8px 15px;
      margin: -20px 20px 0 20px;
      font-size: 18px;
      line-height: 28px;
      border-radius: 8px;
      width:auto;
    }
  
`;

class CategoryProductTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
          isAll: true,
          isGarages: false,
          isCarports: false,
          isBarn: false,
          isCommercial: false,          
          productData: this.props.data,
        }
        this.handleAll = this.handleAll.bind(this)
        this.handleGarages = this.handleGarages.bind(this)
        this.handleCarports = this.handleCarports.bind(this)
        this.handleBarns = this.handleBarns.bind(this)
        this.handleCommercial = this.handleCommercial.bind(this)
      }
      handleAll(data) {
        this.setState({
          isAll: true,
          isGarages: false,
          isCarports: false,
          isBarn: false,
          isCommercial: false,          
          productData: data,
        })
            this.scrollToTarget("categori_list", "category_all");
      }
      handleGarages(data) {
        let garageData = []
        data.forEach(edge => {
          if (edge.node.productCategory.name === "Garages") {
            garageData.push(edge)
          }
        })
        this.setState({
          isAll: false,
          isGarages: true,
          isCarports: false,
          isBarn: false,
          isCommercial: false, 
          productData: garageData,
        });
            this.scrollToTarget("categori_list", "category_garage");
      }
      handleCarports(data) {
        let carportData = []
        data.forEach(edge => {
          if (edge.node.productCategory.name === "Carports") {
            carportData.push(edge)
          }
        })
        this.setState({
          isAll: false,
          isGarages: false,
          isCarports: true,
          isBarn: false,
          isCommercial: false, 
          productData: carportData,
        });
            this.scrollToTarget("categori_list", "category_carport");
      }
      handleBarns(data) {
          let barnData = []
          data.forEach(edge => {
              if (edge.node.productCategory.name === "Barns") {
                  barnData.push(edge)
                }
            })
            this.setState({
                isAll: false,
                isGarages: false,
                isCarports: false,
                isBarn: true,
                isCommercial: false, 
                productData: barnData,
            });
            this.scrollToTarget("categori_list", "category_barn");
        }
        handleCommercial(data) {
          let CommercialData = []
          data.forEach(edge => {
            if (edge.node.productCategory.name === "Commercial Buildings") {
              CommercialData.push(edge)
            }
          })
          this.setState({
            isAll: false,
            isGarages: false,
            isCarports: false,
            isBarn: false,
            isCommercial: true,
            productData: CommercialData,
          });
              this.scrollToTarget("categori_list", "category_commercial");
        }
    
      scrollToTarget(containerId, targetId) {
        const container = document.getElementById(containerId)
        const targetDiv = document.getElementById(targetId)
        container.scrollLeft = targetDiv.offsetLeft - 5
      }
    
    render() {
        const { data, category, location } = this.props
        const {
        isAll,
        isGarages,
        isCarports,
        isBarn,
        isCommercial,
        productData,
        } = this.state
        return (
        <Tabs className='tabs'>
            <TabNav className='tab-nav' id="categori_list">
                <TabItem className="tab-item"
                    id="category_all"
                    onClick={() => this.handleAll(data)}
                >
                    <CategoryCard>
                        <CategoryFigure>
                            <StaticImage src="../../images/all-building.jpg" alt="All Buildings" />
                        </CategoryFigure>
                        <CategoryName color={isAll ? "#ED602B" : "#062C44"}>All Buildings</CategoryName>
                    </CategoryCard>
                </TabItem>
                <TabItem className="tab-item"
                    id="category_garage"
                    onClick={() => this.handleGarages(data)}
                >
                    <CategoryCard>
                        <CategoryFigure>
                            <StaticImage src="../../images/garages.jpg" alt="All Buildings" />
                        </CategoryFigure>
                        <CategoryName color={isGarages ? "#ED602B" : "#062C44"}>Garages</CategoryName>
                    </CategoryCard>
                </TabItem>

                <TabItem className="tab-item"
                    id="category_carport"
                    onClick={() => this.handleCarports(data)}
                >
                    <CategoryCard>
                        <CategoryFigure>
                            <StaticImage src="../../images/carport.jpg" alt="All Buildings" />
                        </CategoryFigure>
                        <CategoryName color={isCarports ? "#ED602B" : "#062C44"} >Carports</CategoryName>
                    </CategoryCard>
                </TabItem>

                <TabItem className="tab-item"
                    id="category_barn"
                    onClick={() => this.handleBarns(data)}
                >
                    <CategoryCard>
                        <CategoryFigure>
                            <StaticImage src="../../images/barn.jpg" alt="All Buildings" />
                        </CategoryFigure>
                        <CategoryName color={isBarn ? "#ED602B" : "#062C44"}>Barns</CategoryName>
                    </CategoryCard>
                </TabItem>
                <TabItem className="tab-item"
                    id="category_commercial"
                    onClick={() => this.handleCommercial(data)}
                >
                    <CategoryCard>
                        <CategoryFigure>
                            <StaticImage src="../../images/commercial.jpg" alt="All Buildings" />
                        </CategoryFigure>
                        <CategoryName color={isCommercial ? "#ED602B" : "#062C44"}>Commercial</CategoryName>
                    </CategoryCard>
                </TabItem>
            </TabNav>
            <TabContent className="tab-content">
                <ProductCardList data={productData} location={location} />
            </TabContent>
        </Tabs>
        )
    }
}

export default CategoryProductTab
